const tableData = [
  {
    id: 1,
    name: 'Recruiting Manstatusr',
    cod: '1',
    qt_used: 50,
    status: 'A',
    discount: 12,
  }, {
    id: 2,
    name: 'Recruiting Manstatusr',
    cod: '222',
    qt_used: 2,
    status: 'I',
    discount: 12,
  }, {
    id: 3,
    name: 'Recruiting Manstatusr',
    cod: '342',
    qt_used: 5,
    status: 'I',
    discount: 12,
  }, 
]

export { tableData };
