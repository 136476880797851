<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";

import { tableData } from "./data-advancedtable";

import {
  required,
} from "vuelidate/lib/validators";

/**
 * Coupons component
 */
export default {
  page: {
    title: "Gerenciar Cupons",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
  },
  data() {
    return {
      tableData: tableData,
      title: "Gerenciar Cupons",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Gerenciar Cupons",
          active: true,
        },
      ],  
      showModal: false,
      tableHighlighted: 0,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      courses: [
        {
          key: 'Curso de Programação',
          value: 1,
        },
        {
          key: 'Curso de Designer / UX',
          value: 2,
        }
      ],
      fieldAliases: {
        id: "#",
        cod: "Código",
        discount: "Disconto(%)",
        qtUsed: "Quantidade Utilizada",
        status: "Status",
        actions: "Ações",
      }, 
      form: {
        cod: "",
        discount: "",
        courses: "",
        validDate: "",
        limit: "",
      },   
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
    }; 
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
    fields() {
      return [
        { key: "id", sortable: true, label: this.fieldAliases.id, thStyle: { width: "8%" } },
        { key: "cod", sortable: true, label: this.fieldAliases.cod },
        { key: "discount", sortable: true, label: this.fieldAliases.discount },
        { key: "qt_used", sortable: true, label: this.fieldAliases.qtUsed },
        { key: "status", sortable: true, label: this.fieldAliases.status, thStyle: { width: "10%" } },
        { key: "actions", sortable: true, label: this.fieldAliases.actions, thStyle: { width: "110px" } }
      ];
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  validations: {
    form: {
      cod: { required },
      discount: { required },
      courses: { required },
      validDate: { required },
      limit: { required },
    },
  },
  methods: {
    formSubmit() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
    },

    /**
     * Validation type submit
     */
    // eslint-disable-next-line no-unused-vars
    typeForm(e) {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },

    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    handleNewCad(value) {
      this.showModal = value;
    },

    deleteItem() {
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" :addText="'Adicionar Novo Cupom'" @newCad="handleNewCad" />
    <div class="row">
      <div class="col-12">
        <div class="card">        
          <div class="card-body">
            <h4 class="card-title">Cupons</h4>
            <p class="card-title-desc"></p>
            <div class="row mb-md-2">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Mostrar
                    <b-form-select class="form-control form-control-sm form-select form-select-sm" v-model="perPage" size="sm" :options="pageOptions"></b-form-select> Registros
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                  <label class="d-inline-flex align-items-center">
                    Pesquisar: 
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Digite algo..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0 datatables">
              <b-table
                :items="tableData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template #cell(status)="obj">
                  <div class="actions-cell">    
                    <span class="badge ms-1" :class="obj.item.status == 'A' ? 'bg-success' : 'bg-warning'">
                      <h6 class="m-0">{{obj.item.status == "A" ? "Ativo" : "Inativo"}}</h6>
                    </span>                   
                  </div>
                </template>
                <!-- Coluna "actions" personalizada -->
                <template #cell(actions)="category">
                  <div class="actions-cell">                    
                    <b-button class="w-100" pill @click="deleteItem(category.item)" variant="outline-primary">
                      <i class="mdi mdi-dots-vertical"></i>
                    </b-button>
                  </div>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> 

    <b-modal v-model="showModal" id="modal-dialog" title="Cadastrar Cupom" title-class="font-18" hide-footer>
      <form class="needs-validation" @submit.prevent="formSubmit">       
        <div class="row mb-4">
          <div class="col">        
            <label class="form-label" for="title">Código <span class="required">*</span></label>
            <div class="input-group">         
              <input
                id="cod"
                v-model="form.cod"
                type="text"
                class="form-control"
                placeholder="Digite o código"
                :class="{
                  'is-invalid': submitted && $v.form.cod.$error,
                }"
              />
              <div
                v-if="submitted && $v.form.cod.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.form.cod.required"
                  >Por favor, digite o código.</span
                >
              </div>
              <div class="input-group-prepend">
                <span
                  id="validationTooltipUsernamePrepend"
                  class="btn btn-primary btn-block inner"
                  ><i class="mdi mdi-sync"></i> Gerar Aleatório</span
                >
              </div>   
            </div>
          </div>
        </div>    
        <div class="row mb-4">
          <div class="col">
            <label class="form-label" for="title">Disconto(%) <span class="required">*</span></label>
            <input
              id="discount"
              v-model="form.discount"
              type="text"          
              v-mask="'##,##'"
              class="form-control"
              placeholder="Digite o desconto"
              :class="{
                'is-invalid': submitted && $v.form.discount.$error,
              }"
            />
            <div
              v-if="submitted && $v.form.discount.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.form.discount.required"
                >Por favor, digite o desconto.</span
              >
            </div>
          </div>
        </div>  
        <div class="row mb-4">
          <div class="col">            
            <label class="form-label" for="courses">Cursos <span class="required">*</span></label>
            <multiselect 
              v-model="form.courses" 
              :options="courses" 
              placeholder="Selecione os cursos"
              label="key" 
              name="courses"
              track-by="value"
              multiple
              :class="{
                'is-invalid':
                  typesubmit && $v.form.courses.$error,
              }"
            ></multiselect>
            <div
              v-if="typesubmit && $v.form.courses.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.form.courses.required"
                >Selecione ao menos 1 curso.</span
              >
            </div>
          </div>
        </div>  
        <div class="row mb-4">
          <div class="col">            
            <div class="custom-control custom-checkbox">
              <input
                v-b-toggle.price-accordion
                class="form-check-input"
                type="checkbox"
                id="invalidCheck"
                required=""
              />
              <label class="custom-control-label" style="margin-left: 10px;" for="is_free_course">
                Limitar cupom?
              </label>
            </div>
            <b-collapse class="paid-course-stuffs mb-4 mt-4" id="price-accordion" accordion="my-accordion">
              <div class="row">
                <div class="col">            
                  <label class="form-label" for="courses">Data de Validade <span class="required">*</span></label>
                  <b-form-input
                    v-model="form.validDate" 
                    type="date"
                    id="valid-date"
                    value=""
                    :class="{
                      'is-invalid':
                        typesubmit && $v.form.validDate.$error,
                    }"
                  ></b-form-input>
                  <div
                    v-if="typesubmit && $v.form.validDate.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.form.validDate.required"
                      >Digite a data de validade.</span
                    >
                  </div>
                </div>
                <div class="col">            
                  <label class="form-label" for="courses">Limites de Uso <span class="required">*</span></label>
                  <input
                    id="limit"
                    v-model="form.limit"
                    type="text"          
                    v-mask="'##,##'"
                    class="form-control"
                    placeholder="Digite o limite de uso"
                    :class="{
                      'is-invalid': submitted && $v.form.limit.$error,
                    }"
                  />
                  <div
                    v-if="typesubmit && $v.form.limit.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.form.limit.required"
                      >Digite o limite de uso.</span
                    >
                  </div>
                </div>
              </div>               
            </b-collapse>
          </div>
        </div>    
        <div class="mt-2 text-end">
            <b-button variant="light" @click="handleNewCad(false)">Fechar</b-button>
            <b-button type="submit" variant="info" class="ms-1"
              >Cadastrar</b-button
            >
          </div>
      </form>    
    </b-modal>
    <!-- end row -->
  </Layout>
</template>
